import React, { useEffect } from 'react'

export default function useValidateLocalData() {
    const [localData, setLocalData] = React.useState(false)
    const [loading, setLoading]= React.useState(true)
    const [refreshNeeded, setRefreshNeed] = React.useState(false)

    useEffect(() => {
        const storageDate = localStorage.getItem('storageDate')
        if (storageDate) {
            setLocalData(true)
            if (new Date().getDate() !== new Date(storageDate).getDate()) {
                setRefreshNeed(true)
                setLoading(false)
            }
            else if (new Date().getDate() === new Date(storageDate).getDate()) {
                setRefreshNeed(false)
                setLoading(false)
            }
        } else if (!storageDate) setLoading(false)
    }, []);

    return {
        loading, refreshNeeded, localData, setLocalData
    }
}
