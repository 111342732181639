import '@LoopKitchen/loop-ui/dist/css/themeStyle.css'
import { OpenAPI as FrontendDataServiceBaseAPI } from '@LoopKitchen/typescript-client/lib/clients/FrontendDataService/core/OpenAPI'
import CssBaseline from '@mui/material/CssBaseline'
import { withProfiler } from '@sentry/react'
import posthog from 'posthog-js'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'

//context
import { theme } from '@LoopKitchen/loop-ui'
import { ThemeProvider } from '@mui/material'
import { ConfirmationResult, RecaptchaVerifier } from 'firebase/auth'
import { pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import AuthContextProvider from 'src/context/AuthContext'
import ErrorContextProvider from 'src/context/ErrorContext'
import LoadingContextProvider from 'src/context/LoadingContext'
import SnackContextProvider from 'src/context/SnackContext'
import Router from 'src/routes/Routes'
import { Userpilot } from 'userpilot'
declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier
    confirmationResult: ConfirmationResult
    dataLayer: any[]
    loop: any
    Intercom: any
    intercomSettings: any
  }
}

Userpilot.initialize('NX-1dd09a02')

posthog.init('phc_eZiJGHUYOJt4IaYW59roQW1Oqz4atnvP7OnccJxauib', {
  api_host: 'https://app.posthog.com'
})

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

FrontendDataServiceBaseAPI.BASE = 'https://api.loopapplication.xyz'

const App = React.memo(() => {
  return (
    <>
      <div id="recaptcha-container" />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <LoadingContextProvider>
            <SnackContextProvider>
              <ErrorContextProvider>
                <AuthContextProvider>
                  <Router />
                </AuthContextProvider>
              </ErrorContextProvider>
            </SnackContextProvider>
          </LoadingContextProvider>
        </BrowserRouter>
      </ThemeProvider>
    </>
  )
})

export default withProfiler(App)
