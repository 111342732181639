import Dexie, { Table } from 'dexie';
interface Options {
  name: string
  value: string
}

export interface Filters {
  // key: string;
  // name: string;
  // selected: any[];
  data: string
}
export interface initialFilters {
  am_name: string
  b_name: string
  chain: string
  error_category: string
  error_subcategory: string
  slug: string
  vb_name: string
  vb_platform: string
}

type AmName = Options
type BName = Options
type VBName = Options
type VBPlatform = Options
type ErrorCategory = Options
type ErrorSubCategory = Options

export class MySubClassedDexie extends Dexie {
  filters: Table<Filters>;
  b_name: Table<BName>
  b_name_string: Table<Filters>
  am_name_string: Table<Filters>
  error_category_string: Table<Filters>
  error_subcategory_string: Table<Filters>
  vb_platform_string: Table<Filters>
  initialfilters_string: Table<Filters>
  vb_platform: Table<VBPlatform>
  am_name: Table<AmName>
  vb_name_string: Table<Filters>
  vb_name: Table<VBName>
  error_category: Table<ErrorCategory>
  error_subcategory: Table<ErrorSubCategory>
  initialFilters: Table<initialFilters>
  filterSet: Table<{data: boolean}>
  errors: Table<{ error: string}>

  constructor() {
    super('filters');
    this.version(3).stores({
      filters: '++id',
      b_name: 'name',
      b_name_string: '++id',
      am_name_string: "++id",
      error_category_string: "++id",
      error_subcategory_string: "++id",
      vb_platform_string: "++id",
      initialfilters_string: "++id",
      vb_platform: 'name',
      am_name: 'name',
      vb_name: "name",
      vb_name_string: '++id',
      error_category: 'name',
      error_subcategory: 'name',
      initialFilters: 'slug',
      filterSet: '++id',
      errors: '++id'
    });
  }
}

export const db = new MySubClassedDexie();
export {default as Dexie} from 'dexie'
export default db