import { Avatar, Box, CircularProgress, Collapse, Icon, Tooltip, useMediaQuery } from '@mui/material'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import { styled, useTheme } from '@mui/material/styles'
import { ErrorBoundary } from '@sentry/react'
import get from 'lodash/get'
import React, { ReactNode, Suspense } from 'react'
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from 'src/context/AuthContext'
import loopLogo1 from '../assets/logos/logo1.png'
import loopLogo from '../assets/logos/logo2.png'
// import Loader from 'src/components/mui/Loader'

//mui icons
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'

import loadable from '@loadable/component'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone'
import KeyboardArrowUpTwoToneIcon from '@mui/icons-material/KeyboardArrowUpTwoTone'
import SentryErrorFallback from 'src/components/errors/SentryErrorFallback'
import { useTrainingConfigContext } from 'src/context/TrainingConfigContext'

const drawerWidth = 280
const NoAccessPage = loadable(() => import('src/pages/noAccess/NoAccess'))

const StyledLogo = styled((props: { src: string }) => (
  <img
    alt="img"
    {...props}
  />
))(({ theme, open }: { theme: any; open: boolean }) => ({
  marginLeft: open ? 16 : 'auto',
  marginTop: 16,
  marginBottom: 80,
  marginRight: open ? 0 : 'auto'
}))

const StyledIcon = styled(IconButton)({
  backgroundColor: '#2F4A5A',
  '&:hover': { backgroundColor: '#2F4A5A' },
  position: 'relative',
  left: '103%'
})

type LinkProps = {
  icon: string | ReactNode
  to: string
  label: string
  open: boolean
  isSubNav: boolean
  isLinkActive: boolean
}

const CustomLink: React.FC<LinkProps> = ({ icon, to, label, open, isSubNav = 'false', isLinkActive = 'false' }) => (
  <NavLink
    to={to}
    style={{ textDecoration: 'none' }}>
    {({ isActive }) => {
      if (isActive) document.title = `Loop - ${label}`
      return (
        <ListItem
          selected={isActive}
          disablePadding
          sx={{
            color: isActive ? 'white' : 'gray',
            '&:hover': { color: 'white' }
          }}>
          <ListItemButton
            sx={{
              justifyContent: open ? 'initial' : 'center'
            }}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
                color: 'inherit'
              }}>
              <Icon>{icon}</Icon>
            </ListItemIcon>
            {open && (
              <ListItemText
                primary={label}
                color="inherit"
              />
            )}
          </ListItemButton>
          {open && isSubNav && (isLinkActive ? <KeyboardArrowUpTwoToneIcon /> : <KeyboardArrowDownTwoToneIcon />)}
        </ListItem>
      )
    }}
  </NavLink>
)

const Drawer = styled(MuiDrawer)(
  ({ theme }) => `
      flex-shrink: 0;
      white-space: nowrap;
      box-sizing: border-box;
      transition: ${theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })};
      &.open {
          width: ${drawerWidth}px;
          .MuiDrawer-paper {
              width: ${drawerWidth}px;
              box-sizing: border-box;
              transition: ${theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
              })};
          }
      }
      &.close {
          width: 60px;
          transition: ${theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          })};
          .MuiDrawer-paper {
              transition: ${theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
              })};
              width: 60px;
          }
      }
  `
)

type Page = {
  label: string
  key: string
  icon: string | ReactNode
  subNav?: {
    label: string
    key: string
  }[]
}

function SubNav({ page, open, icon }: { page: Page; open: boolean; icon: string | ReactNode }) {
  const location = useLocation()
  const navigate = useNavigate()
  const [openMenu, setOpenMenu] = React.useState(false)

  const handleClick = () => {
    if (open === false) navigate(`/${page.key}/${get(page, 'subNav[0].key')}`)
    else setOpenMenu(!openMenu)
  }

  React.useEffect(() => {
    if (open === false && openMenu === true) {
      setOpenMenu(false)
    }
  }, [open, openMenu])

  const isActive = location.pathname.startsWith(`/${page.key}/`)
  const activeColor = 'white'
  const inactiveColor = '#808080'
  return (
    <List sx={{ py: 0, fontSize: 12 }}>
      <ListItemButton
        sx={{
          color: isActive ? activeColor : inactiveColor,
          '&:hover': { color: activeColor }
        }}
        onClick={handleClick}>
        <ListItemIcon>
          <Icon>{icon}</Icon>
        </ListItemIcon>
        <ListItemText
          sx={{ fontSize: 12 }}
          primary={page.label}
        />
        {openMenu ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        in={openMenu}
        timeout="auto"
        unmountOnExit>
        <List
          component="div"
          disablePadding>
          {get(page, 'subNav', []).map((subPage) => {
            const isActive = location.pathname.startsWith(`/${page.key}/${subPage.key}`)
            return (
              <ListItemButton
                sx={{
                  color: isActive ? activeColor : inactiveColor,
                  '&:hover': { color: activeColor },
                  pl: 1
                }}
                component={Link}
                to={`/${page.key}/${subPage.key}`}
                key={subPage.key}>
                <ListItemIcon sx={{ color: 'inherit', ml: 2 }}>
                  <SubdirectoryArrowRightIcon />
                </ListItemIcon>
                <ListItemText primary={subPage.label} />
              </ListItemButton>
            )
          })}
        </List>
      </Collapse>
    </List>
  )
}
export default function PrivateLayout({ noAccess }: { noAccess: boolean }) {
  const theme: any = useTheme()
  const { trainingCategoryList } = useTrainingConfigContext()
  const sm = useMediaQuery(theme.breakpoints.down('md'))
  const [open, setOpen] = React.useState(true)
  // const [panelConfig, setPanelConfig] = React.useState([])
  const { currentUser, logout, orgConfig } = useAuth()
  const toggleDrawer = () => setOpen(!open)
  const [routes, setRoutes] = React.useState<Page[]>([])
  const [accessRoutes, setAccessRoutes] = React.useState([])

  React.useEffect(() => {
    if (currentUser && orgConfig) {
      const accessLevel = get(currentUser, 'access-level', '')
      const tempRoutes = get(orgConfig, `accessConfig.${accessLevel}.navConfig`, [])
      setAccessRoutes(tempRoutes)
    }
  }, [currentUser, orgConfig])

  React.useEffect(() => {
    if (sm === true) setOpen(false)
    if (sm === false) setOpen(true)
  }, [sm])

  React.useEffect(() => {
    //ADD SIDEBAR ROUTES HERE
    const internalRoutes = [
      ...trainingCategoryList.map((e) => {
        return {
          label: e.label,
          icon: e.icon?.value || 'home',
          key: `category/${e.uid}`
        }
      })
    ]
    setRoutes(internalRoutes)
    // if (internalUser.includes('@loopkitchen.xyz')) {
    //   if (accessRoutes.length > 0) {
    //     setRoutes([...accessRoutes, ...internalRoutes])
    //   } else {
    //     setRoutes([...get(orgConfig, 'navConfig', []), ...internalRoutes])
    //   }
    // } else {
    //   if (accessRoutes.length > 0) {
    //     setRoutes(accessRoutes)
    //   } else {
    //     setRoutes(get(orgConfig, 'navConfig', []))
    //   }
    // }
  }, [trainingCategoryList])

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        className={open ? 'open' : 'close'}
        sx={{
          '& .MuiDrawer-paper': {
            bgcolor: theme.colors.navyBlack,
            border: 'none',
            color: 'white',
            borderRadius: '0px 0px 0px 0px',
            zIndex: theme.zIndex.appBar,
            overflow: 'visible',
            maxHeight: '100vh'
          },
          '& .MuiBox-root::-webkit-scrollbar-track': {
            borderRadius: '10px',
            backgroundColor: theme.colors.navyBlack
          },
          '& .MuiBox-root::-webkit-scrollbar': {
            borderRadius: '4px',
            width: '7px',
            backgroundColor: theme.colors.navyBlack
          },
          '& .MuiBox-root::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            backgroundColor: '#2F4A5A'
          },
          bgcolor: theme.colors.background
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '30px',
            marginBottom: open ? '20px' : '10px',
            flexDirection: 'row'
          }}>
          <StyledLogo
            theme={theme}
            sx={{
              display: open ? 'flex' : 'none',
              m: 'auto',
              height: 40,
              objectFit: 'contain',
              marginLeft: '16px'
            }}
            src={loopLogo}
            open={open}
          />
          <StyledLogo
            theme={theme}
            sx={{
              display: !open ? 'flex' : 'none',
              height: 40,
              m: 'auto',
              marginLeft: '9px',
              objectFit: 'contain'
            }}
            src={loopLogo1}
            open={open}
          />
          <div
            style={{
              justifyContent: 'center',
              display: open ? 'flex' : 'none',
              transition: 'display 225ms'
            }}>
            <Tooltip
              title={currentUser?.org}
              arrow
              placement="top">
              <Avatar sx={{ bgcolor: '#f15412' }}>{get(currentUser, 'org', '').slice(0, 1).toUpperCase()}</Avatar>
            </Tooltip>
          </div>
          <Toolbar sx={{ marginLeft: open ? '-5px' : '-20px', width: '10%' }}>
            <StyledIcon
              size="small"
              onClick={toggleDrawer}>
              {!open ? <ChevronRightIcon sx={{ color: 'white' }} /> : <ChevronLeftIcon sx={{ color: 'white' }} />}
            </StyledIcon>
          </Toolbar>
        </Box>
        <div
          style={{
            display: open ? 'none' : 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
            transition: 'display 225ms'
          }}>
          <Tooltip
            title={currentUser?.org}
            arrow
            placement="top">
            <Avatar sx={{ bgcolor: '#f15412', width: 38, height: 38 }}>{(get(currentUser, 'org', '') || '').slice(0, 1).toUpperCase()}</Avatar>
          </Tooltip>
        </div>
        <List
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '3px'
          }}>
          {/* {
                          panelConfig?.map(page => {
                              return !page.subPages ? <CustomLink key={page.id} open={open} to={`/${page.key}${window.location.search}`} label={page.name} icon={<Dashboard />} />
                                  : <BasicMenu key={page.id} open={open} page={page} icon={<Dashboard />} />
                          })
                      } */}
          {/* <CustomLink open={open} to={`/issue-discovery${window.location.search}`} label="Issue Discovery" icon={<DocumentScannerIcon />} /> */}
          {/* <CustomLink open={open} to={`/bnm${window.location.search}`} label="Brick & Mortar" icon={<StackedBarChartIcon />} /> */}
          <Box
            height="100%"
            sx={{
              overflowX: 'hidden',
              overflowY: 'hidden',
              display: 'flex',
              flexDirection: 'column'
            }}
            pb={open ? 30 : 0}>
            {routes.map((navItem) => {
              return navItem.subNav && navItem.subNav.length ? (
                <SubNav
                  key={navItem.key}
                  open={open}
                  page={navItem}
                  icon={navItem.icon || <DocumentScannerIcon />}
                />
              ) : (
                <CustomLink
                  key={navItem.key}
                  open={open}
                  to={`/${navItem.key}`}
                  label={get(navItem, 'label')}
                  icon={navItem.icon}
                  isSubNav={false}
                  isLinkActive={false}
                />
              )
            })}
            {/* <div
                style={{
                  flexGrow: 1,
                  minHeight: 'calc(100vh - 450px)'
                }}
              /> */}
            {/* <ListItem disablePadding>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: 'inherit',
                    ml: 2
                  }}
                >
                  <Avatar
                    sx={{ height: 30, width: 30 }}
                    src={currentUser?.photoURL}
                  />
                </ListItemIcon>

                <Fade in={open} timeout={500}>
                  <ListItemText
                    primary={currentUser?.displayName}
                    secondary={currentUser?.email}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: 'inherit',
                      '& .MuiListItemText-secondary': {
                        color: 'rgba(255, 255, 255, 0.2)'
                      }
                    }}
                  />
                </Fade>
              </ListItem>
              <ListItem button onClick={() => logout()} disablePadding>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: 'inherit',
                    ml: 2.5
                  }}
                >
                  <Logout />
                </ListItemIcon>

                <Fade in={open} timeout={500}>
                  <ListItemText
                    primary="Logout"
                    sx={{
                      opacity: open ? 1 : 0,
                      color: 'inherit',
                      '& .MuiListItemText-secondary': {
                        color: 'rgba(255, 255, 255, 0.2)'
                      },
                      cursor: 'pointer'
                    }}
                  />
                </Fade>
              </ListItem> */}
          </Box>
        </List>
        <Divider />
      </Drawer>
      <Suspense
        fallback={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
              width: '100%'
            }}>
            <CircularProgress color="inherit" />
          </Box>
        }>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            zIndex: theme.zIndex.appBar - 2,
            bgcolor: theme.colors.background,
            minHeight: '100vh',
            flex: '1 1 auto',
            overflowY: 'hidden',
            textAlign: 'left'
          }}>
          <ErrorBoundary
            fallback={({ error }) => {
              return <SentryErrorFallback error={error} />
            }}>
            {
              !currentUser ? <>{noAccess ? <NoAccessPage /> : <Outlet />}</> : <Outlet context={{ drawerState: open }} />
              // <Loader loading />
            }
          </ErrorBoundary>
        </Box>
      </Suspense>
    </Box>
  )
}
