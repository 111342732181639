import { OpenAPI as ActionServiceBaseAPI } from '@LoopKitchen/typescript-client/lib/clients/FrontendDataService/core/OpenAPI'
import axios from 'axios'
import Dexie from 'dexie'
import { onAuthStateChanged, signInWithCustomToken, signOut } from 'firebase/auth'
import get from 'lodash/get'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useErrorData } from 'src/context/ErrorContext'
import db from 'src/db/db'
import { auth, clearList, fetchGoogleStudioLinks, fetchOrgConfig, fetchUserOrgAndRole } from 'src/services/firebase'
import firebaseConfig from 'src/services/firebaseConfig'
import { OrgConfig, UserFirebaseData } from 'src/types/adminTypes'
import { RefreshAccessData, User } from 'src/types/user'
import { checkAuthStatus } from 'src/utils/firebase/checkAuthStatus'
import { clearSession } from 'src/utils/firebase/clearSession'
import { getLocal, setLocal } from 'src/utils/functions/localStorage'
import useValidateLocalData from 'src/utils/hooks/useValidateLocalData'
import { useSnackData } from './SnackContext'
interface PageDetails {
  path: string
  visitTime: number
}

interface AuthContextInterface {
  loading: boolean
  currentUser?: User
  logout: () => void
  orgConfig: OrgConfig
  googleStudioLinks: string[]
  pageDetails: PageDetails
  defaultRoute: string
  setPageDetails: (arg0: PageDetails) => void
  setDefaultRoute: React.Dispatch<React.SetStateAction<string>>
  getValidAccessToken(user: User): Promise<{
    accessToken: string
    expirationTime: number
  }>
  isDemo: boolean
}

const initialState = {} as AuthContextInterface

export const AuthContext = createContext<AuthContextInterface>(initialState)

export const useAuth = () => useContext(AuthContext)

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation()
  const { openError } = useSnackData()
  const [currentUser, setCurrentUser] = useState<User>()
  const [loading, setLoading] = useState(true)
  const { handleError } = useErrorData()
  const [orgConfig, setOrgConfig] = useState({} as OrgConfig)
  const [defaultRoute, setDefaultRoute] = useState('dashboard')
  const { refreshNeeded, localData, loading: localDataLoading, setLocalData } = useValidateLocalData()
  const [googleStudioLinks, setGoogleStudioLinks] = useState([])
  const [pageDetails, setPageDetails] = useState<PageDetails>({
    path: location.pathname,
    visitTime: new Date().getTime()
  })
  const isDemo = get(currentUser, 'access-level', []).includes('demo') || false

  async function getNewAccessToken(refreshToken: string) {
    try {
      const { data }: { data: RefreshAccessData } = await axios.post(`https://securetoken.googleapis.com/v1/token?key=${firebaseConfig.apiKey}`, {
        grant_type: 'refresh_token',
        refresh_token: refreshToken
      })
      return data
    } catch (error: any) {
      if (handleError) handleError(error.message)
    }
  }

  async function getValidAccessToken(user: User) {
    const { accessToken, expirationTime, refreshToken } = get(user, 'stsTokenManager')
    if (new Date(expirationTime) < new Date()) {
      // this condition will run when access token is expired
      // use refresh token to get another accesstoken
      const data = await getNewAccessToken(refreshToken)
      console.log(data)
      return {
        accessToken: data?.access_token,
        expirationTime: new Date().setHours(new Date().getHours() + 1)
      } //new access token
    }
    return { accessToken, expirationTime } // old access token
  }

  useEffect(() => {
    const getApiData = async (currentUser: User, data: UserFirebaseData) => {
      try {
        setLoading(true)
        setCurrentUser({
          ...currentUser,
          ...data
        })
        let result: any = await Promise.allSettled([fetchGoogleStudioLinks(), fetchOrgConfig(data.org), fetchOrgConfig('default')]).catch((error) => {
          console.log(error.message)
          if (handleError) handleError(error.message)
        })
        result = result.map((item: { value: string }) => item.value)
        setGoogleStudioLinks(result[0])
        const accessConfig = get(result, '[1].accessConfig', {})
        const defaultRouteForRole = get(accessConfig, `${data['access-level']}.defaultRoute`, get(result, '[1].defaultRoute', 'dashboard'))
        if (defaultRouteForRole) setDefaultRoute(defaultRouteForRole)
        let navConfig = result[1]?.navConfig
        let filterConfig = result[1]?.filterConfig
        let isVirtual = result[1]?.isVirtual
        // let domains = result[1]?.domain
        // ;(window as any).heap.identify(currentUser.email)
        // ;(window as any).heap.addUserProperties({
        //   Name: currentUser.displayName,
        //   Email: currentUser.email,
        //   Role: data.role,
        //   Org: data.org,
        //   CustomerType:
        //     domains &&
        //     domains.some((domain) => currentUser.email.includes(domain))
        //       ? 'ACTUAL'
        //       : 'TEST'
        // })
        if (!navConfig) {
          navConfig = result[2]?.navConfig
        }
        if (!filterConfig) {
          filterConfig = result[2]?.filterConfig
        }
        if (typeof isVirtual === 'undefined') {
          isVirtual = result[2]?.isVirtual
        }

        // @ts-ignore
        setOrgConfig({
          navConfig,
          filterConfig,
          isVirtual,
          // @ts-ignore
          accessConfig,
          access_levels: accessConfig
        })

        localStorage.setItem('storageDate', new Date().toDateString())

        //set all data to localstorage
        setLocal('currentUser', {
          ...currentUser,
          ...data
        })
        // setLocal('navConfig', navConfig)
        // setLocal('accessConfig', accessConfig)
        // setLocal('defaultRoute', defaultRouteForRole)
        // setLocal('filterConfig', filterConfig)
        // setLocal(
        //   'isVirtual',
        //   result[1]?.isVirtual || result[2]?.isVirtual || true
        // )
        setLocal('data', data)
        setLocal('studio_links', result[0])
      } catch (error: any) {
        console.log(error.message)
        if (handleError) handleError(error.message)
      }
      setLoading(false)
    }
    const getLocalData = async (data: any) => {
      try {
        //get all data from localstorage
        const localCurrentUser: User = getLocal('currentUser')
        // const localNavConfig = getLocal('navConfig')
        // const localFilterConfig = getLocal('filterConfig')
        // const localIsVirtual = getLocal('isVirtual')
        // const accessConfig = getLocal('accessConfig')
        const localStudioLinks = getLocal('studio_links')
        const localDefaultRoute = getLocal('defaultRoute')
        if (localDefaultRoute) setDefaultRoute(localDefaultRoute)

        const result = await getValidAccessToken(localCurrentUser)
        console.log(result)
        const { accessToken, expirationTime } = result
        const newLocalUser = {
          ...localCurrentUser,
          accessToken,
          stsTokenManager: {
            accessToken,
            expirationTime,
            refreshToken: get(localCurrentUser, 'stsTokenManager.refreshToken')
          },
          'access-level': get(data, 'access-level')
        }
        setLocal('currentUser', newLocalUser)

        setCurrentUser(newLocalUser)
        setGoogleStudioLinks(localStudioLinks)
        const orgConfig = await fetchOrgConfig(newLocalUser.org)
        setOrgConfig(orgConfig)
        // setOrgConfig({
        //   navConfig: localNavConfig,
        //   filterConfig: localFilterConfig,
        //   isVirtual: localIsVirtual,
        //   accessConfig
        // })
      } catch (error: any) {
        console.log(error.message)
        if (handleError) handleError(error.message)
      }
      setLoading(false)
    }
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      try {
        db.open()
        const condition = Boolean(auth.currentUser !== null && currentUser !== null)
        if (condition) {
          const data = await fetchUserOrgAndRole(currentUser.uid)
          const localOrg = getLocal('userOrg')
          // const localUser: string = localStorage.getItem('currentUser')
          // if user data from localstorage matches currentUser.uid then don't make api calls
          if (!localDataLoading) {
            const orgCondition = get(data, 'org') === localOrg
            // if (localData && !refreshNeeded) {
            if (localData && !refreshNeeded && orgCondition) {
              //local data
              await getLocalData(data)
              // } else if ((localData && refreshNeeded) || !localData) {
            } else if ((localData && refreshNeeded) || !localData) {
              //api data
              // @ts-ignore
              await getApiData(currentUser, data)
            } else if (orgCondition) getLocalData(data)
            // @ts-ignore
            else if (!orgCondition) getApiData(currentUser, data)
          }
        } else {
          clearList()
          setCurrentUser(undefined)
          setLoading(false)
        }
      } catch (error: any) {
        setLoading(false)
        console.log(error.message)
        if (handleError) handleError(error.message)
      }
    })
    return () => unsubscribe()
  }, [refreshNeeded, localData, localDataLoading])

  React.useEffect(() => {
    const getToken = async () => {
      if (auth.currentUser) {
        const token = await auth.currentUser.getIdToken()
        ActionServiceBaseAPI.TOKEN = token
      }
    }
    getToken()
  }, [currentUser])

  const logout = React.useCallback(async () => {
    try {
      await clearSession()
      setCurrentUser(undefined)
      await signOut(auth)
      localStorage.clear()
      sessionStorage.clear()
      sessionStorage.clear()
      setLocalData(false)
      Dexie.delete('filters').then(() => db.open())
    } catch (error: any) {
      console.log(error.message)
      if (handleError) handleError(error.message)
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    try {
      const checkAuth = async () => {
        const res = await checkAuthStatus()

        if (res && res.success) {
          const idToken = res.token
          signInWithCustomToken(auth, idToken)
        } else {
          if (currentUser) await logout()

          window.location.href = `https://auth.tryloop.ai/?redirect_uri=${window.location.href}&logout=true`
          // window.location.href = `http://localhost:3001/?redirect_uri=${window.location.href}&logout=true`
        }
      }

      checkAuth()
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }, [currentUser])

  const contextValue = useMemo(
    () => ({
      currentUser,
      loading,
      logout,
      orgConfig,
      googleStudioLinks,
      pageDetails,
      setPageDetails,
      defaultRoute,
      setDefaultRoute,
      getValidAccessToken,
      isDemo
    }),
    [currentUser, loading, logout, orgConfig, googleStudioLinks, pageDetails, setPageDetails, defaultRoute, setDefaultRoute, getValidAccessToken, isDemo]
  )

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export default AuthContextProvider
