import React, { useEffect } from 'react'
import Loading from 'src/components/mui/Loader'
import { useAuth } from '../context/AuthContext'

import loadable from '@loadable/component'
import { capitalize } from '@mui/material'
import posthog from 'posthog-js'
import TrainingConfigContextProvider from 'src/context/TrainingConfigContext'
import PrivateRouter from 'src/routes/PrivateRouter'

const NoInternetPage = loadable(() => import('src/pages/noInternet/NoInternet'))
const NoAccess = loadable(() => import('src/pages/noAccess/NoAccess'))

const Router = React.memo(() => {
  const { currentUser, loading } = useAuth()
  if (!navigator.onLine) return <NoInternetPage />

  useEffect(() => {
    if (!currentUser) {
      return
    }
    const { uid, email, org } = currentUser

    const accessLevel = currentUser['access-level']
    const fullName = currentUser['name']

    let user = {
      id: uid,
      signup_date: Date.now() / 1000,
      userId: uid,
      fullName: capitalize(fullName),
      email,
      company: org,
      accessLevel,
      avatar: currentUser?.photoURL
    }
    posthog.identify(user.email, user)
    posthog.capture('$pageview')
    posthog.group('company', user.company, user)
    posthog.register({
      email: user.email
    })
  }, [currentUser])

  return (
    <>
      {loading ? (
        <Loading loading />
      ) : (
        <>
          {!currentUser ? (
            <Loading loading />
          ) : (
            <TrainingConfigContextProvider>
              <PrivateRouter noAccess={false} />
            </TrainingConfigContextProvider>
          )}
        </>
      )}
    </>
  )
})

export default Router
