import axios from 'axios'

export const clearSession = async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_FIREBASE_FUNCTION}/auth/signout`,
      {},
      {
        withCredentials: true
      }
    )
    return res.data
  } catch (err) {
    console.error(err)
  }
}
