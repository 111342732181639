
import React, { createContext, useContext, useMemo, useState } from 'react'
interface LoadingContextInterface {
  loading: LoaderTypes
  stopLoading: () => void
  startLoading: () => void
  setLoading: React.Dispatch<React.SetStateAction<LoaderTypes>>
}

type LoaderTypes = boolean

const initialState = {} as LoadingContextInterface

const LoadingContext = createContext<LoadingContextInterface>(initialState)

export const useLoadingData = () => useContext(LoadingContext)

const LoadingContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [loading, setLoading] = useState<LoaderTypes>(false)

    const startLoading = () => setLoading(true)
    const stopLoading = () => setLoading(false)

    const contextValue = useMemo(
      () => ({ loading, setLoading, startLoading, stopLoading }),
      [loading, setLoading, startLoading, stopLoading]
    )

    return (
        <LoadingContext.Provider value={contextValue}>{children}</LoadingContext.Provider>
    )
}

export default LoadingContextProvider