import Dexie from 'dexie'
import React, { createContext, useContext, useMemo } from 'react'
import db from 'src/db/db'
// import { track } from 'src/utils/analytics/analytics'
import { useSnackData } from 'src/context/SnackContext'
import { useLoadingData } from './LoadingContext'
interface ErrorContentInterface {
  handleError(error: string): void
  asyncWrapper(fn: any): Promise<void>
  openError: (text: string) => void
  openSuccess: (text: string) => void
  handleAsync(fn: () => Promise<any>): Promise<any>
}

export const schemaError = "Failed to execute 'transaction' on 'IDBDatabase'"
export const VersionError = 'VersionError'
export const databaseClosedError = 'DatabaseClosedError'

const initialState = {} as ErrorContentInterface

const ErrorContext = createContext<ErrorContentInterface>(initialState)

export const useErrorData = () => useContext(ErrorContext)

const ErrorContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { setSnack, openSuccess, openError } = useSnackData()
  const { setLoading } = useLoadingData()

  function handleError(error: string) {
    if (error && typeof error === 'string') {
      if (
        error.slice(0, 12) === VersionError ||
        error.slice(0, 48) === schemaError ||
        error.slice(0, 19) === databaseClosedError
      ) {
        return Dexie.delete('filters')
          .then(() => db.open())
          .catch((error) => console.log(error.message))
      }
      setSnack({ type: 'error', open: true, text: error })
      // track({ action: error })
    }
  }
  async function asyncWrapper(fn: any) {
    try {
      return await fn
    } catch (error: any) {
      if (error.name === Dexie.errnames.DatabaseClosed) {
        return
      }
      handleError(error.message)
    }
  }
  async function handleAsync(fn: () => Promise<any>) {
    try {
      const result = await fn()
      setLoading(false)
      return result
    } catch (error: any) {
      if (error.name === Dexie.errnames.DatabaseClosed) {
        return
      }
      handleError(error.message)
      setLoading(false)
    }
  }

  const contextValue = useMemo(
    () => ({ handleError, asyncWrapper, openError, openSuccess, handleAsync }),
    [handleError, asyncWrapper, openError, openSuccess, handleAsync]
  )

  return (
    <ErrorContext.Provider value={contextValue}>
      {children}
    </ErrorContext.Provider>
  )
}

export default ErrorContextProvider
