import * as Sentry from '@sentry/react'
import LogRocket from 'logrocket'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'

// logrocket
LogRocket.init('nmu9lo/app')

// sentry
Sentry.init({
  dsn: 'https://5a16efc7cf78e4f856b1b20eb8cc3dbe@o4504559796682752.ingest.sentry.io/4505861924716544',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment:
    window.location.href.indexOf('loop-training.vercel.app') != -1
      ? 'production'
      : window.location.href.indexOf('localhost') != -1
      ? 'development'
      : 'preview',
  release: process.env.REACT_APP_VERSION,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  //<React.StrictMode>
  <App />
  // {/* </React.StrictMode> */}
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
