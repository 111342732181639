import loadable from '@loadable/component'
import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { useTrainingConfigContext } from 'src/context/TrainingConfigContext'
import { PrivateLayout } from 'src/layouts'

const CategoryDisplay = loadable(
  () => import('src/pages/members/CategoryDisplay/CategoryDisplay')
)

const PrivateRouter = React.memo(
  ({ noAccess = false }: { noAccess?: boolean }) => {
    const { loading, trainingCategoryList } = useTrainingConfigContext()

    const firstCategoryUID = React.useMemo(() => {
      if (!trainingCategoryList || trainingCategoryList.length === 0) {
        return undefined
      }
      return trainingCategoryList[0].uid
    }, [trainingCategoryList])

    return (
      <>
        {!loading &&
          useRoutes([
            {
              path: '/',
              element: <PrivateLayout noAccess={noAccess} />,
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to={`category/${
                        firstCategoryUID ? firstCategoryUID : ''
                      }`}
                    />
                  ),
                },
                {
                  path: 'category/:category_uid',
                  element: <CategoryDisplay />,
                },
                { path: '*', element: <Navigate to="/" replace /> },
              ],
            },
          ])}
      </>
    )
  }
)

export default PrivateRouter
