import ErrorIcon from '@mui/icons-material/Error'
import CustomTypo, { TypoContainer } from 'src/components/StyledTypo'

const SentryErrorFallback = (error: { error: { message: string } }) => {
  if (!error) {
    return
  }

  if (error.error.message.includes('Loading chunk') && error.error.message.includes('failed')) {
    window.location.reload()
  }

  return (
    <TypoContainer sx={{ alignItems: 'center', height: '100%' }}>
      <ErrorIcon sx={{ color: 'gray', fontSize: 40 }} />
      <CustomTypo
        fs={40}
        fw={400}
        color="gray">
        Something wrong with this section
      </CustomTypo>
    </TypoContainer>
  )
}
export default SentryErrorFallback
