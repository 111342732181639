import { collection, onSnapshot } from 'firebase/firestore'
import React from 'react'
import { db } from './../services/firebase'

const trainingConfigName = 'training_config'
const trainingCategoryName = 'training_category'

export type TrainingConfigType = {
  uid: string
  label: string
  urlType: 'PDF' | 'Video' | 'LOOM'
  url: string
  hide: boolean
}

export type TrainingCategoryType = {
  uid: string
  label: string
  icon?: { label: string; value: string }
  trainingConfigs: TrainingConfigType[]
}

interface TrainingConfigContextInterface {
  loading: boolean
  trainingConfigList: TrainingConfigType[]
  trainingCategoryList: TrainingCategoryType[]
}

const TrainingConfigContext = React.createContext(
  {} as TrainingConfigContextInterface
)

export const useTrainingConfigContext = () =>
  React.useContext(TrainingConfigContext)

interface TrainingConfigContextProviderProps {
  children: React.ReactNode
}

export default function TrainingConfigContextProvider(
  props: TrainingConfigContextProviderProps
) {
  // User for initial page loading
  const [configStatus, setConfigStatus] = React.useState<string>()

  // User for initial page loading
  const [categoryStatus, setCategoryStatus] = React.useState<string>()
  const [trainingConfigList, setTrainingList] = React.useState<
    TrainingConfigType[]
  >([])
  const [trainingCategoryList, setTrainingCategoryList] = React.useState<
    TrainingCategoryType[]
  >([])

  const loading = React.useMemo(() => {
    if (categoryStatus === 'done' && configStatus === 'done') {
      return false
    }
    return true
  }, [categoryStatus, configStatus])

  React.useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, trainingConfigName),
      (snapshot) => {
        setTrainingList(
          snapshot.docs.map((e) => ({
            ...e.data(),
            uid: e.id,
          })) as TrainingConfigType[]
        )
        setConfigStatus('done')
      }
    )

    return () => {
      unsubscribe()
    }
  }, [])

  React.useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, trainingCategoryName),
      (snapshot) => {
        const result = snapshot.docs.map((e) => {
          const obj = e.data() as Omit<
            TrainingCategoryType,
            'trainingConfigs'
          > & { trainingConfigs: { uid: string; hide: boolean }[] }

          const data: TrainingCategoryType = {
            ...obj,
            trainingConfigs: obj.trainingConfigs
              .map((item) => {
                const config = trainingConfigList.find(
                  (e) => e.uid === item.uid
                )
                if (!config) {
                  return undefined
                }
                return { ...config, localHide: item.hide }
              })
              .filter(
                (e) => !!e && !e.hide && !e.localHide
              ) as TrainingCategoryType['trainingConfigs'],
          }

          return data
        })
        setTrainingCategoryList(result)
        setCategoryStatus('done')
      }
    )

    return () => {
      unsubscribe()
    }
  }, [trainingConfigList])

  const contextValue = React.useMemo(() => {
    return {
      loading,
      trainingCategoryList,
      trainingConfigList,
    }
  }, [loading, trainingCategoryList, trainingConfigList])

  return (
    <TrainingConfigContext.Provider value={contextValue}>
      {props.children}
    </TrainingConfigContext.Provider>
  )
}
